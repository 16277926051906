<template>
  <div class="flix-container flix-form-group">
    <div class="flix-row">
      <div class="flix-col-md-4 flix-col-md-flix-offset-4">
        <div class="flix-well flix-text-center">
          <div class="flix-form-group">
            <div class="flix-html-h1" >
              <flixIcon :id="'keys'"/>
            </div>
            <div class="flix-html-h2">
              {{ $t('message.forgotPassword') }}
            </div>
          </div>
          <transition name="flixFadeIn">
            <div :key="mailsend">
              <div v-if="mailsend">
                <div class="flix-alert flix-alert-success">{{ $t('message.successfullSendet') }}</div>
              </div>
              <form @submit.prevent="resendPassword()" v-else>
                <transition name="flixFadeIn">
                <div class="flix-form-group">
                  <input type="email" v-model="email" class="flix-form-control" spellcheck="false" :placeholder="$t('message.email')"/>
                </div>
                </transition>
                <transition name="flixFadeIn">
                  <div class="flix-form-group" v-if="checkemail">
                    <button type="submit" class="flix-btn flix-btn-block flix-btn-default">{{ $t("message.send") }}</button>
                  </div>
                </transition>
              </form>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      email: '',
      checkemail: false,
      user: false,
      mailsend: false
    }
  },
  watch: {
    email (val) { this.checkemail = false; if (this.$checkEmail(val)) { this.checkemail = true } }
  },
  methods: {
    resendPassword () {
      this.getUser(function () {
        this.getSendEmail()
      }.bind(this))
    },
    getSendEmail () {
      var send = this.$t('email.resendPassword')
      send.user = this.user.md5_id
      var date = new Date()
      date.setHours(date.getHours() * 1 + 24)

      send.to = this.email.toLowerCase().trim()

      var link = this.$flix_url + '/reset-password/' + btoa(btoa(JSON.stringify({ user: this.user.md5_id, date: date.getTime(), rand: Math.random(0, 1) })))
      send.text = send.text.split('{link}').join('<a href="' + link + '">' + link + '</a>')

      this.$flix_post({
        url: 'user/registermail',
        data: send,
        callback: function (ret) {
          if (ret.data[0]) {
            this.mailsend = true
          }
        }.bind(this)
      })
    },
    getUser (callback) {
      this.$flix_post({
        url: 'user/lost_password',
        data: {
          username: this.email.toLowerCase().trim(),
          noEmail: true
        },
        callback: function (ret) {
          if (ret.data[0]) {
            this.user = ret.data[1]
            callback()
          }
        }.bind(this)
      })
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
